import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { slugify } from "../../../utils";

const RentalFilter = ({ categories }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="rental-filter-menu">
            <button className="active is-checked" data-filter="*">
                {t("rental.category.all")}
            </button>
            {categories?.map((cat, idx) => (
                <button key={idx} data-filter={`.${slugify(cat)}`}>
                    <span className="filter-text">{t(cat)}</span>
                </button>
            ))}
        </div>
    );
};

RentalFilter.propTypes = {
    categories: PropTypes.array,
};

export default RentalFilter;
