import { useState, useEffect } from "react";

const useCart = () => {
    const [cartItems, setCartItems] = useState(() => {
        const storedCart = localStorage.getItem("cartItems");

        return storedCart ? JSON.parse(storedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (productId) => {
        setCartItems((prev) => {
            if (prev.includes(productId)) {
                return prev;
            }
            return [...prev, productId];
        });
    };

    const removeFromCart = (productId) => {
        setCartItems((prev) => prev.filter((id) => id !== productId));
    };

    const clearCart = () => {
        setCartItems([]);
    };

    return { cartItems, addToCart, removeFromCart, clearCart };
};

export default useCart;
