import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import { slugify } from "../../../utils";
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay]);

const RentalTile = ({ data }) => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;
    const product = data;

    return (
        <div className="post-item">
            <div className="thumb">
                <Link
                    to={
                        process.env.PUBLIC_URL + `/rental-details/${product.id}`
                    }
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/${product.media.thumb}`}
                        alt="lipkovska.art-Rental"
                    />
                </Link>
            </div>
            <div className="content">
                <h4 className="title">
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/rental-details/${product.id}`
                        }
                    >
                        {product.title}
                    </Link>
                </h4>
                <h6 className="cate-link">
                    {product[currenLanguage].shortDescription}
                </h6>
                <Link
                    className="btn-link"
                    to={
                        process.env.PUBLIC_URL + `/rental-details/${product.id}`
                    }
                >
                    {t("rental.moreButton")}
                </Link>
            </div>
        </div>
    );
};

RentalTile.propTypes = {
    data: PropTypes.object,
};

export default RentalTile;
