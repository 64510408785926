import React from "react";
import RentalFilter from "../../../components/rental/rental-filter";
import RentalTile from "../../../components/rental/rental-tile";
import RentalData from "../../../data/rental.json";
import useMasonry from "../../../hooks/use-masonry";
import { slugify } from "../../../utils";

const RentalContainer = () => {
    const { categories } = useMasonry(
        RentalData,
        ".masonryGrid",
        ".masonry-item",
        ".rental-filter-menu",
        ".rental-filter-menu button"
    );
    return (
        <div className="rental-area rental-masonry-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <RentalFilter categories={categories} />
                    </div>
                </div>
                <div className="row masonryGrid post-items-style1">
                    {RentalData &&
                        RentalData.map((product) => (
                            <div
                                key={product.id}
                                className={`col-sm-6 col-md-6 col-lg-4 masonry-item ${product.categories
                                    .map((cat) => slugify(cat))
                                    .join(" ")}`}
                            >
                                <RentalTile data={product} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default RentalContainer;
