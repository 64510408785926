import PropTypes from "prop-types";
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import RentalDetailsContainer from "../containers/rental/rental-details";
import RentalData from "../data/rental.json";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import { CartProvider } from "../hooks/cart-provider";

const RentalDetails = ({
    match: {
        params: { id },
    },
}) => {
    const data = RentalData.filter((rental) => rental.id === id);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Rental Details" />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        <CartProvider>
                            <RentalDetailsContainer data={data[0]} />
                        </CartProvider>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

RentalDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default RentalDetails;
