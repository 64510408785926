import React, { useEffect, useState, useCallback } from "react";

const ScrollToTop = (props) => {
    const [stick, setStick] = useState(false);

    const onClickHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const scrollHandler = useCallback(() => {
        const scrollPos = window.scrollY;
        setStick(scrollPos > 200);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [scrollHandler]);

    return (
        <button
            type="button"
            className={`scroll-top ${stick ? "show" : ""}`}
            onClick={onClickHandler}
            {...props}
        >
            <i className="arrow-top icofont-rounded-up"></i>
            <i className="arrow-bottom icofont-rounded-up"></i>
        </button>
    );
};

export default ScrollToTop;
