import React from "react";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BusinessService from "../containers/price/business-service/index.jsx";
import PrivateService from "../containers/price/private-service/index.jsx";
import AdditionalService from "../containers/price/additional-service/index.jsx";
import PageTitleContainer from "../containers/global/page-title-without-photo";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import PriceData from "../data/price-data.json";

const Price = () => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;

    const data = {
        subTitle: PriceData.title.subTitle[currenLanguage],
        title: PriceData.title[currenLanguage],
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title={t("price.title")} />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <PageTitleContainer data={data} />
                    <div className="main-content">
                        <div className="post-details-content">
                            <BusinessService />
                            <PrivateService />
                            <AdditionalService />
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Price;
