import { createContext } from "react";
import useCart from "./use-сart";
import PropTypes from "prop-types";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const cart = useCart();

    return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

CartProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CartProvider;
