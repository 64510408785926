import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const ContactInfo = ({ data }) => {
    const { t } = useTranslation();

    const trackLinkClick = (label) => {
        ReactGA.event({
            category: "User Interaction",
            action: "Clicked Contact Link",
            label: label,
        });
    };

    return (
        <div
            className="info-item info-item2"
            data-aos="fade-up"
            data-aos-delay={data.delay}
        >
            <div className="info-title">
                <h4>{t(data.title)}</h4>
                <div className="icon">
                    <i className={data.icon}></i>
                </div>
            </div>
            <div className="info-content">
                {data.links?.map((link, index) => (
                    <p key={index}>
                        <a
                            href={link.url}
                            onClick={() => trackLinkClick(link.tracking)}
                            target={
                                link.url.startsWith("http") ? "_blank" : "_self"
                            }
                            rel="noopener noreferrer"
                        >
                            {link.display}
                        </a>
                    </p>
                ))}
            </div>
        </div>
    );
};

ContactInfo.propTypes = {
    data: PropTypes.shape({
        delay: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                url: PropTypes.string,
                tracking: PropTypes.string,
                display: PropTypes.string,
            })
        ),
    }),
};

export default ContactInfo;
