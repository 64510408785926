import PageTitle from "../../../components/page-title";
import PropTypes from "prop-types";

const PageTitleContainer = ({ data }) => {
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle={data.subTitle}
                            title={data.title}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

PageTitleContainer.propTypes = {
    data: PropTypes.object,
};

export default PageTitleContainer;
