import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import RentalContainer from "../containers/rental/rental-grid";
import { useTranslation } from "react-i18next";
import PageTitleContainer from "../containers/global/page-title-without-photo";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";

const Rental = () => {
    const { t } = useTranslation();
    const data = {
        title: t("rental.subTitle"),
        subTitle: t("rental.title"),
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title={t("rental.title")} />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        <PageTitleContainer data={data} />
                        <RentalContainer />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Rental;
