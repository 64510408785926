import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactGA from "react-ga4";

SwiperCore.use([Pagination]);

const RentalDetailsContainer = ({ data }) => {
    const { t, i18n } = useTranslation();
    const currenLanguage = i18n.language;

    const trackLinkClick = (label) => {
        ReactGA.event({
            category: "User Interaction",
            action: "Clicked Contact Link",
            label: label,
        });
    };

    return (
        <div className="rental-details-area">
            <div className="post-details-content">
                <div className="post-details-body">
                    <div className="bread-crumbs">
                        <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                        <span>&#8250;</span>
                        <Link to={process.env.PUBLIC_URL + "/rental"}>
                            Rental
                        </Link>
                        <span>&#8250;</span>
                        <span className="active">{data.title}</span>
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 resizer">
                                <div className="post-media">
                                    <Swiper
                                        className="post-gallery"
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        loop
                                        pagination={{ clickable: true }}
                                    >
                                        {data.media &&
                                            data.media?.images.map(
                                                (image, key) => {
                                                    return (
                                                        <SwiperSlide key={key}>
                                                            <img
                                                                key={key}
                                                                src={`${process.env.PUBLIC_URL}/${image.source}`}
                                                                alt="lipkovska.art"
                                                                width="400"
                                                            />
                                                        </SwiperSlide>
                                                    );
                                                }
                                            )}
                                    </Swiper>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 resizer">
                                <h2 className="title">{data.title}</h2>
                                <div className="row rent-price">
                                    <div className="col-md-6">
                                        {t("rental.label.rentPrice")}:
                                    </div>
                                    <div className="col-md-6 rent-price-value">
                                        {data.price}$/{t("rental.label.perDay")}
                                    </div>
                                </div>
                                <div className="row full-price text-muted">
                                    <div className="col-md-6">
                                        {t("rental.label.fullPrice")}:
                                    </div>
                                    <div className="col-md-6 full-price-value">
                                        {data.originalPrice}$
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-8 col-12 interested-label mb-3">
                                        Interested? Message me directly!
                                    </div>
                                    <div className="col-md-1 col-2">
                                        <a
                                            href="https://www.instagram.com/direct/t/105909130811771"
                                            onClick={() =>
                                                trackLinkClick(
                                                    "Instagram Direct from Rent"
                                                )
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icofont-instagram icofont-2x"></i>
                                        </a>
                                    </div>
                                    <div className="col-md-1 col-2">
                                        <a
                                            href="https://www.facebook.com/messages/t/110480787125295"
                                            onClick={() =>
                                                trackLinkClick(
                                                    "Facebook Messenger from Rent"
                                                )
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icofont-facebook-messenger icofont-2x"></i>
                                        </a>
                                    </div>
                                    <div className="col-md-1 col-2">
                                        <a
                                            href="https://t.me/lipkovska_art"
                                            onClick={() =>
                                                trackLinkClick(
                                                    "Telegram from Rent"
                                                )
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icofont-telegram icofont-2x"></i>
                                        </a>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr />
                                <div className="product-highlights padding-bottom-25">
                                    <h5>{t("rental.label.highlights")}</h5>
                                    {data[currenLanguage].features?.map(
                                        (value, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    dangerouslySetInnerHTML={{
                                                        __html: value,
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 resizer">
                                {data[currenLanguage].description.map(
                                    (value, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="desc"
                                                dangerouslySetInnerHTML={{
                                                    __html: value,
                                                }}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

RentalDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default RentalDetailsContainer;
